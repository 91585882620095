.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.horizontal-menu {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap between menu items as needed */
  margin: 20px 0; /* Add some margin for spacing */
  background-color: #f0f0f0; /* Background color for the menu */
  padding: 10px; /* Padding inside the menu */
  border-radius: 5px; /* Optional: Rounded corners for the menu */
}

.horizontal-menu a {
  text-decoration: none;
  color: black; /* Adjust the text color as needed */
  padding: 10px 20px; /* Add padding for a better look */
  border: 1px solid transparent; /* Add border for better spacing */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.horizontal-menu a:hover {
  background-color: #ddd; /* Change background color on hover */
  color: black; /* Change text color on hover */
  border: 1px solid #ccc; /* Add border on hover */
}